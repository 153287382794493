<template>
  <div :style='`width:${width}; height:${height}`' v-loading="loading" :class="CSSClasses">
    <vue-apex-charts
      :series="series"
      :options="options"
      :data-type="editorChart.type"
      :data-source-id="sourceId"
      :data-alias="editorAlias || null"
      ref="chart"
      height="100%"
      width="100%"
      type="bar"
    ></vue-apex-charts>
    <slot></slot>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import mixin from '../mixins'
import registryMixin from '../registry/registry_mixins'
import VisibilityMixin from '@/mixins/visibility'
import FilterBuilder, { EComponentTypes } from '../utils'

import { getCounterFilters } from '@/components/InterfaceEditor/components/basic/HelpersForBasicCmp/index.js'

export default {
  name: 'a-barchart',
  components: {
    VueApexCharts
  },
  inject: {
    getRegistryRecordId: { default: () => () => {} },
    forceUpdateSettingsPanel: { default: () => () => {} },
    getComponents: { default: () => () => [] }
  },
  mixins: [mixin, registryMixin, VisibilityMixin],
  props: {
    size: {
      frozen: true
    },
    align: {
      frozen: true
    },
    margin: {
      frozen: true
    },
    readonly: {
      frozen: true
    },
    hiddenCondition: {
      frozen: true
    },
    block: {
      frozen: true
    },
    isRequired: {
      frozen: true
    },
    wrapper: {
      frozen: true
    },
    alwaysActive: {
      frozen: true
    },
    editorAlias: {
      type: String,
      description: 'alias'
    },
    editorChart: {
      type: Object,
      editor: 'Chart',
      default: () => {
        return {
          type: 'plugin',
          colors: ['#69B3E7', '#3F95DA', '#0977CB', '#0058B9', '#0039A3', '#001489']
        }
      },
      options: { type: 'bar' }
    },
    params: {
      type: String,
      description: 'add_parameters'
    },
    dataClickPlugin: {
      type: String,
      description: 'plugin_click'
    },
    editChart: {
      type: Boolean,
      description: 'merge_series'
    },
    valueLegend: {
      type: Boolean,
      description: 'meaning_legend'
    },
    dataLabelsEnabled: {
      type: Boolean,
      description: 'value_graph',
      default: true
    },
    hideXaxis: {
      type: Boolean,
      description: 'show_x_axis',
      default: true
    },
    hideYaxis: {
      type: Boolean,
      description: 'show_y_axis',
      default: true
    },
    width: {
      type: String,
      description: 'width',
      default: '100%'
    },
    height: {
      type: String,
      description: 'height',
      default: '100%'
    },
    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true,
        showEqualsTypes: true
      }
    }
  },

  data () {
    return {
      loaderInstance: undefined,
      loading: false,
      countFilters: 0,
      isLoadData: true,
      isInitCmpDone: false,
      categories: [],
      options: {
        colors: undefined,
        title: {},
        plotOptions: {
          bar: {
            horizontal: true
            // barHeight: '95%'
          }
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          show: this.hideXaxis,
          labels: {
            show: this.hideXaxis
          },
          axisBorder: {
            show: this.hideXaxis
          },
          axisTicks: {
            show: this.hideXaxis
          }
        },
        fill: {
          opacity: 1

        },
        chart: {
          events: {
            dataPointSelection: this.onDataClick
          },
          animations: {
            enabled: false
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            }
          },
          stacked: this.editChart
        },
        grid: {
          xaxis: {
            lines: {
              show: this.hideXaxis
            }
          },
          yaxis: {
            lines: {
              show: this.hideYaxis
            }
          }
        },
        yaxis: {
          show: this.hideYaxis,
          labels: {
            show: this.hideYaxis
          },
          axisBorder: {
            show: this.hideYaxis
          },
          axisTicks: {
            show: this.hideYaxis
          },
          title: {
            text: undefined
          }
        },
        dataLabels: {
          enabled: this.dataLabelsEnabled,
          formatter: function (val, opt) {
            return (val || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            // return new Intl.NumberFormat('ru-RU').format(val)
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          formatter: (seriesName, opts) => {
            if (this.valueLegend) {
              return [seriesName, ' - ', opts.w.globals.series[opts.seriesIndex]]
            } else {
              return [seriesName]
            }
          },
          offsetX: 40
        }
      },
      series: []
    }
  },
  computed: {
    dataFilters () {
      if (this.editorChart.type === 'plugin') {
        let filters = []
        if (this.filters) {
          this.filters.forEach((item) => {
            if (!item.type || item.type === 'field') {
              if (this.getModel()[item.attribute] && item.alias) {
                filters.push(`${item.alias}=${this.getModel()[item.attribute]}`)
              }
            } else if (item.type === 'constant' && item.alias) {
              filters.push(`${item.alias}=${item.attribute}`)
            }
          })
        }
        return filters
      } else {
        const builder = new FilterBuilder(
          this.filters,
          this.getModel(),
          this.$store,
          EComponentTypes.barChart
        )

        const filters = builder.buildAsApiQl()
        if (filters.length === 0 && this.isEditor()) {
          return {
            limit: 10
          }
        }
        if (filters.length > 0 && this.isEditor()) {
          return {
            where: {
              and: [...filters]
            },
            limit: 20
          }
        }
        if (filters.length > 0) {
          return {
            where: {
              and: [...filters]
            }
          }
        }

        return {}
      }
    },
    sourceId () {
      return this.editorChart?.extendObject || this.editorChart?.requestsId || null
    }
  },
  watch: {
    dataFilters: {
      handler (val) {
        // первое условие актуально при первой загрузке
        if (!this.isLoadData && !this.isInitCmpDone) {
          if (this.countFilters == val?.where?.and.length) {
            this.loadData()
            this.isInitCmpDone = true
          }
        } else if (this.isInitCmpDone) {
          this.loadData()
        }
      }
    },
    hideXaxis (value) {
      this.$nextTick(() => {
        if (this.$refs.chart) {
          this.$refs.chart.updateOptions({
            xaxis: {
              show: value,
              labels: {
                show: value
              },
              axisBorder: {
                show: value
              },
              axisTicks: {
                show: value
              }
            }
          })
        }
      })
    },
    hideYaxis (value) {
      this.$nextTick(() => {
        if (this.$refs.chart) {
          this.$refs.chart.updateOptions({
            yaxis: {
              show: value,
              labels: {
                show: value
              },
              axisBorder: {
                show: value
              },
              axisTicks: {
                show: value
              }
            }
          })
        }
      })
    },
    valueLegend (value) {
      this.$nextTick(() => {
        if (this.$refs.chart) {
          this.$refs.chart.updateOptions({
            legend: {}
          })
        }
      })
    },
    dataLabelsEnabled (value) {
      this.$nextTick(() => {
        if (this.$refs.chart) {
          this.$refs.chart.updateOptions({
            dataLabels: {
              enabled: value
            }
          })
        }
      })
    },
    editorChart: {
      handler (value) {
        this.options.title = Object.assign({}, this.options.title, value.title)
        this.options.legend = Object.assign({}, this.options.legend, value.legend)
        this.options.colors = value.colors
        // закоментировал loadData - наблюдаем..
        // this.loadData()
        this.updateBarChart()
      },
      immediate: true,
      deep: true
    },
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },
  async mounted () {
    if ((this.editorChart.type === 'plugin' && !this.editorChart.plugin) ||
            (this.editorChart.type === 'extended_object' && !this.editorChart.extendObject) ||
            (this.editorChart.type === 'requests' && !this.editorChart.requestsId)
    ) {
      return false
    }
    this.onVisibilityChange(() => {
      this.$nextTick(() => {
        if (this.$refs.chart && this.$refs.chart.chart && this.$refs.chart.$el.children[0].offsetHeight === 0) {
          this.$refs.chart.chart.update()
        }
      })
    })
    this.countFilters = getCounterFilters(this.getComponents, this.filters)
    // Если фильтров нет, то при иницилизации компонента грузим данные
    if (!this.countFilters) {
      this.isLoadData = true
      // console.log('грузим данные')
    } else {
      this.isLoadData = false
      // console.log('будет watch')
    }
    // await this.loadData()
    // !replicationGuid - компонент вне тиражируемых контейнеров
    if (this.isLoadData && !this.replicationGuid) {
      await this.loadData()
      this.isLoadData = false
      this.isInitCmpDone = true
    }
    // костыль
    if (this.replicationGuid) {
      this.loading = true
      console.log('загрузка a-barchart в тиражируемом контейнере')
      setTimeout(() => {
        this.loadData()
        this.isInitCmpDone = true
      }, 2000)
    }
    // предохранитель
    setTimeout(() => {
      if (!this.series.length && !this.isLoadData && !this.replicationGuid) {
        console.warn('timeout')
        this.loadData()
        this.isInitCmpDone = true
      }
    }, 5000)
  },
  methods: {
    updateBarChart () {
      this.$nextTick(() => {
        if (this.$refs.chart) {
          // this.$refs.chart.refresh()
          this.$refs.chart.updateOptions({
            xaxis: {
              show: this.hideXaxis,
              labels: {
                show: this.hideXaxis
              },
              axisBorder: {
                show: this.hideXaxis
              },
              axisTicks: {
                show: this.hideXaxis
              }
            },
            title: {},
            yaxis: {
              show: this.hideYaxis,
              labels: {
                show: this.hideYaxis
              },
              axisBorder: {
                show: this.hideYaxis
              },
              axisTicks: {
                show: this.hideYaxis
              }
            },
            legend: {},
            dataLabels: {
              enabled: this.dataLabelsEnabled
            }
          })
        }
      })
    },
    onDataClick (event, chartContext, config) {
      if (this.dataClickPlugin) {
        import(`@/plugins/${this.$config.project}/${this.dataClickPlugin}`)
          .then((Plugin) => {
            let instance = new Plugin.default(this)
            instance.execute(event, chartContext, config)
          }).catch(() => {
            console.log(`Plugin: ${this.dataClickPlugin} not found`)
          })
      }
    },
    async loadData () {
      this.loading = true
      // if (!this.loaderInstance) {
      //   this.loaderInstance = this.$loading({ target: this.$el })
      // }
      if (this.editorChart.type === 'plugin' && this.editorChart.plugin) {
        let data = await this.$http.get(`${this.$config.api}/registryservice/plugins/execute/${this.editorChart.plugin}?recordId=${this.getRegistryRecordId() || 0}&${this.dataFilters.join('&')}&${this.params || ''}`)
          .finally(() => {
            // this.loaderInstance && this.loaderInstance.close()
            // this.loaderInstance = undefined
            this.loading = false
          })
        if (data.data) {
          this.$refs.chart.updateOptions({
            xaxis: {
              categories: data.data.categories.map(item => item || '')
            }
          })
          this.series = data.data.series
        }
      } else if (this.editorChart.type === 'extend_object') {
        let data = await this.$http
          .post(`${this.$config.api}/datawarehouseservice/extended_object/${this.editorChart.extendObject}`, this.dataFilters, { hideNotification: true })
          .finally(() => {
            // this.loaderInstance && this.loaderInstance.close()
            // this.loaderInstance = undefined
            this.loading = false
          })
        let series = this.getExtendedObjectSeries(data.data)
        this.$refs.chart.updateOptions({
          xaxis: {
            categories: this.getExtendedObjectCategories(data.data)
          }
        })
        this.series = series
      } else if (this.editorChart.type === 'requests' && this.editorChart.requestsId) {
        const data = await this.$http
          .post(`${this.$config.api}/datawarehouseservice/query/${this.editorChart.requestsId}`, this.dataFilters, { hideNotification: true })
          .finally(() => {
            // this.loaderInstance && this.loaderInstance.close()
            // this.loaderInstance = undefined
            this.loading = false
          })
        let series = this.getExtendedObjectSeries(data.data)
        if (this.multiYaxis) {
          this.setYaxis(series)
        }
        this.$refs.chart.updateOptions({
          xaxis: {
            categories: this.getRequestsCategories(data.data)
          }
        })
        this.series = series
      } else {
        // this.loaderInstance && this.loaderInstance.close()
        // this.loaderInstance = undefined
        this.loading = false
      }
      this.updateBarChart()
    },
    getExtendedObjectSeries (data) {
      let colors = []
      let answer = this.editorChart.series.map((item) => {
        let object = {}
        object.name = item.name

        object.data = data.map(row => row[item.field.name || item.fieldRequest])
        // костыль
        if (item.color && !Array.isArray(item?.category?.color)) {
          colors.push(item.color)
        } else if (Array.isArray(item?.category?.color)) {
          colors.push(item.category.color[0])
        } else {
          colors = item.category.color
        }
        return object
      })
      this.$refs.chart.updateOptions({
        colors: colors
      })
      this.$set(this.options, 'colors', colors)
      return answer
    },
    getExtendedObjectCategories (data) {
      return data.map(item => item[this.editorChart.categorie.name] + '')
    },
    getRequestsCategories (data) {
      return data.map(item => item[this.editorChart.categorieRequests] + '').filter((value, index, self) => self.indexOf(value) === index)
    }
  }
}
</script>

<style scoped>

</style>
